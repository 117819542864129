export default function (csv, filename = "campaign_details.csv") {
  // Convert the CSV string to a Blob
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create download link
  const hiddenElement = document.createElement("a");
  hiddenElement.href = url;
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;

  // Append to document, click, and cleanup
  document.body.appendChild(hiddenElement);
  hiddenElement.click();

  // Cleanup
  document.body.removeChild(hiddenElement);
  window.URL.revokeObjectURL(url);
}
